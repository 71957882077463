.Body {
    display: block;
    position: relative;
   
    width: 100vw;
}

.Email {

   display: block;
   position: relative;

text-align: center;
   margin-top: 750px;

}



.Rectangle {

width: 55vw;
height: 620px;
top:50px;

background-color:#1B1A1B;


position: absolute;
right: 0;



}



.TextAbout {
    text-transform: uppercase;
    
    color: white;
    letter-spacing: 8px;
    text-align: right;
    margin-right: 70px;
    line-height: 1.5;
    -webkit-animation: fadein 0.8s;
    animation: fadein 0.8s;

}

.TextAbout p:nth-child(1) {
    margin-top:65px;

    font-weight: 700;
    font-size: 50px;
    letter-spacing: 9.69px;
   
    
}

.TextAbout p:nth-child(2) {
    font-weight: 500;
    font-size: 30px;
    opacity: 0.6;
}

.TextAbout p:nth-child(3) {
    font-weight: 500;
    font-size: 30px;
    opacity: 0.6;

}

.TextAbout p:nth-child(4) {
    font-weight: 500;
    font-size: 30px;
    opacity: 0.6;

}
.AboutMe {
display: relative;
    text-align: right;
    margin-right: 75px;
    margin-top: 80px;
    
}

.AboutMe:hover .Line {
margin-right: -225px;

}

.AboutMe:hover p {

    opacity: 0;
}

.AboutMe p {

    color:white;
    font-size: 30px;
    font-weight: 500;
    margin-top: -60px;
    margin-right: 40px;
    letter-spacing: 9.69px;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    z-index: 2;
}
.Line {
    
    position: absolute;
    background-color: white;
    height: 2px;
    width: 180px;
    right: 370px;
    margin-top:-42px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    
    
}

.NotVisible {

    display: none;
}

.Descr {
width: 481px;
height: 401px;
right: 60px;
margin-top: 90px;
font-size: 22px;
text-align: right;
color: white;

letter-spacing: 2.93px;
position: absolute;
-webkit-animation: fadein 0.4s;
        animation: fadein 0.4s;
line-height: 35px;
font-weight: 500;



}

@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.Description span{
color: #8a8bd1

}

.Niko {
    
  position: absolute;
    width:1004px;
    height: 560px;
    margin-left: 5%;
    margin-top: 80px;
    
    }

#root > div > div.Body > div.Niko > div {
display:inline-block;
    position: absolute;
    
    z-index: 3;
    
}



#root > div > div.Body > div.Niko > div::before{
content: '';
display: block;


position: absolute;
width: 1006px;
height: 563px;
margin-top: -1px;

background:url('./NikoNew.svg');

background-repeat: no-repeat;
background-size: contain;
z-index: 4;


}



@media all and (max-width: 1700px) {
.Niko {
    width:895.3px;
    height: 500px;
    margin-left: 5%;
    margin-top: 100px;
    
} 
.Rectangle {
width: 55vw;
height: 600px;
top:50px;
}
#root > div > div.Body > div.Niko > div::before {

    width: 899px;
height: 502px;
margin-top: 0px;
margin-left: 1px;
}


.TextAbout p:nth-child(1) {
margin-top: 52px;

}
.Descr {
margin-top: 70px;
font-size: 22px;
width: 470px;
}
.Email {

    margin-top: 740px;
}

}


@media all and (max-width: 1500px) {
    .Niko {
       
        width:679.9px;
        height: 378px;
        margin-left: 5%;
        margin-top: 130px;
        
    } 
    .Rectangle {
    width: 55vw;
    height: 540px;
    top:50px;
    }
    #root > div > div.Body > div.Niko > div::before {
    
        width: 680.9px;
    height: 380px;
    margin-top: -1.1px;
    margin-left: 1px;
     
    }
    
    
    .TextAbout p:nth-child(1) {
    margin-top: 28px;
    -webkit-transform: scale(0.9);
        -ms-transform: scale(0.9);
            transform: scale(0.9);
    
    }
    .TextAbout p {

        -webkit-transform: scale(0.9);

            -ms-transform: scale(0.9);

                transform: scale(0.9);
    }
    
    .AboutMe {
        -webkit-transform: scale(0.9);
            -ms-transform: scale(0.9);
                transform: scale(0.9);
    }

    .AboutMe:hover .Line {
        margin-right: -300px;
        
        }
.Descr{
        margin-top: 90px;
font-size: 14px;
width: 420px;


}

.Email {
margin-top: 700px;

}
    }

    @media all and (max-width: 1200px) {
        .Niko {
           
            width:501.2px;
            height: 280px;
            margin-left: 5%;
            margin-top: 140px;
            
        } 
        .Rectangle {
        width: 60vw;
        height: 460px;
        top:50px;
        }
        #root > div > div.Body > div.Niko > div::before {
        
            width: 501.2px;
        height: 280px;
        margin-top: 0px;
        margin-left: 1px;
         
        }
        
        
        .TextAbout p:nth-child(1) {
        margin-top: 16px;
        -webkit-transform: scale(0.7);
            -ms-transform: scale(0.7);
                transform: scale(0.7);
        
        
        }
        .TextAbout p {
    
            -webkit-transform: scale(0.7);
    
                -ms-transform: scale(0.7);
    
                    transform: scale(0.7);
            
        }
        
        .AboutMe {
            -webkit-transform: scale(0.7);
                -ms-transform: scale(0.7);
                    transform: scale(0.7);
            margin-top: 20px;
        }
    
        .AboutMe:hover .Line {
            margin-right: -300px;
            
            }


.Descr {
    margin-top: 45px;
font-size: 14px;
width: 380px;


}
.Email {

    margin-top: 620px;
}


        }

        
        @media all and (max-width: 992px) {
.Email {
    margin-top: 0;
    -webkit-transform: translate(10px);
        -ms-transform: translate(10px);
            transform: translate(10px);
}
            
            .Niko {
               
               display: block;
position: relative;
              
              
                width:501.2 !important;
                
               
                height: 280px;
                text-align: center;
                -webkit-transform: translate(-15%);
                    -ms-transform: translate(-15%);
                        transform: translate(-15%);
                
                margin-top: 80px;
                
                
            } 
            
            .Rectangle {
                display: block;
                background: black;
                position: relative;
                margin: auto;
            width: 60vw;
            height: 460px;
            top:50px;
         
            }
            #root > div > div.Body > div.Niko > div::before {
            
                width: 501.2px;
            height: 280px;
            margin-top: 0px;
            margin-left: 1px;

            content: '';
display: block;


position: absolute;



background:url('./NikoBlack.svg');

background-repeat: no-repeat;
background-size: contain;
z-index: 4;


            
             
            }
            
            
            .TextAbout p:nth-child(1) {
            margin-top: 16px;
            -webkit-transform: scale(0.7);
                -ms-transform: scale(0.7);
                    transform: scale(0.7);
            text-align: center;
            
            
            }
            .TextAbout p {
        
                -webkit-transform: scale(0.7);
        
                    -ms-transform: scale(0.7);
        
                        transform: scale(0.7);
                text-align: center;
                
            }
            
            .AboutMe {
                -webkit-transform: scale(0.7);
                    -ms-transform: scale(0.7);
                        transform: scale(0.7);
                margin-top: 20px;
                text-align: center;
            }
        
            .AboutMe:hover .Line {
                margin-right: -290px;
                
                }
                .Line {
                    display: none;
                }

                .TextAbout  {
                   margin: auto;
                    position: relative;
                }
                .TextAbout p {
                    
                    -webkit-transform: scale(0.7);
                    
                        -ms-transform: scale(0.7);
                    
                            transform: scale(0.7);
                    
                }

                .TextAbout p:nth-child(2) {
                    margin-top: -20px !important;
                }
               .Rectangle {
                   width: 80%;
               }
               .AboutMe {
                   display: block;
                   margin: auto;
               }

               .AboutMe p {
                   margin: auto;
                   margin-top: -60px;
               }

               .Description {
                   display: block !important;
                   width: 90%;
                   
               }

               .Descr {
                   display: block !important;
                   width: 90%;
                   right: 0;
                   text-align: center;
                   
                   
                   
                   
                margin-top: 70px;
                margin-right: 36px;
                
               
        font-size: 20px;
        }
        .react-player {
            position: fixed !important;
            text-align: center;
            -webkit-transform: translate(-3%);
                -ms-transform: translate(-3%);
                    transform: translate(-3%);
        }

               
            }

            @media all and (max-width: 800px) {
.Niko {
   margin-left: 0px;
   -webkit-transform: translate(-28%);
       -ms-transform: translate(-28%);
           transform: translate(-28%);
}

.Descr {
    display: block !important;
    width: 100%;
    right: -10px;;
    text-align:right;
    margin: auto;
    
    
    
    
 margin-top: 0px;
 margin-right: 32px;
 

font-size: 12px;
}


.react-player {
    position: fixed !important;
    text-align: center;
    -webkit-transform: translate(3%);
        -ms-transform: translate(3%);
            transform: translate(3%)
}
/*  Changes to  mobile screen */
.Niko {
    display: none;
}


.TextAbout p:nth-child(1) {
    font-size: 24px;
    text-align: center;
    margin-bottom: 42px;
}
.TextAbout p:nth-child(2) {
    font-size: 22px;
    text-align: center;
}

.TextAbout p:nth-child(3) {
    font-size: 22px;
    text-align: center;
}

.TextAbout p:nth-child(4) {
    font-size: 22px;
    text-align: center;
}
.Line {
    display: none;
}
.AboutMe
 {
     display: block;
     position: relative;
     margin-top: 80px;

     
     
}

.AboutMe p:hover {
opacity: 0.6;
color: #8a8bd1;
}

.Email {
    margin-top: 50px;
    transform: translateX(-0px);
    
}


.AboutMe img {
    margin-left: -9px;
    display: none;
}








            }       

            
    
            
        
    




